<template>
  <div class="w center_all handle-template">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>微信传播</li>
        <li>-></li>
        <li @click="goToWebTemplate">通用组件模板</li>
        <li>-></li>
        <li>{{ templateTitle }}微网刊主页模版</li>
      </ul>
      <div class="edits">
        <el-form ref="handleTemplate" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
          <el-form-item label="模版名称:" prop="templateName" placeholder="请输入模版名称">
            <el-input v-model="ruleForm.templateName" type="text" />
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="模版类别:" prop="templateType">
                <div class="templateType-list">
                  <div class="templateType-item" v-for="(item, index) in templateTypeList" :key="index">
                    <el-image class="templateType-item-img" :src="item.img" alt />
                    <el-radio :disabled="$route.params.templateState === 'edit'" v-model="ruleForm.templateType" :label="item.value">{{ item.name }}</el-radio>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="配置模版组件:">
            <component v-if="isShow" :is="currentTemplateComList[ruleForm.templateType]" :mouldConfig="mouldConfig"> </component>
          </el-form-item>
        </el-form>
      </div>
      <div class="btns_form btns" style="text-align: center">
        <el-button type="primary" @click="submitTemplateInfo">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// 自科遥感版 模板显示0-9
// 社科版 模板显示0,1,3,6,7,8
// 医学版 模板显示0,9,6,4,7
// 含能材料版 模板显示0,9,3,10,11,7
import zkTemplate from './components/templateType/zkTemplate.vue'
import skTemplate from './components/templateType/skTemplate.vue'
import yxTemplate from './components/templateType/yxTemplate.vue'
import hnclTemplate from './components/templateType/hnclTemplate.vue'
import { UploadPictureStream, getTemplate, submitWorktemplate } from '@/api/configManage'
export default {
  name: 'handle-template',
  components: {},
  computed: {
    templateTitle() {
      return this.$route.params.templateState == 'create' ? '创建' : '编辑'
    }
  },
  watch: {
    'ruleForm.templateType'() {
      this.templateTypeChange()
    },
    'mouldConfig.modlue7.type'(n) {
      let type = String(n)
      this.mouldConfig.modlue7.imgSrc = require(`@/assets/img/template_img/sk/module7-type${type}.png`)
    },
    'mouldConfig.modlue6.type'(n) {
      let type = String(n)
      this.mouldConfig.modlue6.imgSrc = require(`@/assets/img/template_img/sk/module6-type${type}.png`)
    },
    'mouldConfig.modlue1.type'(n) {
      let type = String(n)
      this.mouldConfig.modlue1.imgSrc = require(`@/assets/img/template_img/sk/module1-type${type}.png`)
    }
  },
  data() {
    return {
      morImg: require('@/assets/img/img_default.png'),
      isShow: false,
      templateState: 'create',
      currentTemplateComList: {
        1: zkTemplate, //自科类/遥感
        2: skTemplate, // 社科类
        3: yxTemplate, // 医学类
        4: hnclTemplate // 含能材料类
      },
      mouldConfig: {},
      UploadPictureStream: UploadPictureStream,
      templateTypeList: [
        {
          name: '遥感学报样式',
          img: require('@/assets/img/template_img/zk/module.png'),
          value: 1
        },
        {
          name: '中山大学社科版样式',
          img: require('@/assets/img/template_img/sk/module.png'),
          value: 2
        },
        {
          name: '中华口腔医学样式',
          img: require('@/assets/img/template_img/yx/module.png'),
          value: 3
        },
        {
          name: '含能材料样式',
          img: require('@/assets/img/template_img/hncl/module.png'),
          value: 4
        }
      ],
      ruleForm: {
        templateName: '',
        templateType: 4
      },
      rules: {
        templateName: [{ required: true, message: '请输入模版名称', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    if (this.$route.params.templateState == 'edit') {
      this.getTemplateDetail()
    } else {
      this.ruleForm = {
        templateName: '',
        templateType: 1
      }
      this.initMouldConfig()
    }
  },
  methods: {
    // 格式化数据
    formatterData(mouldConfig) {
      if (!mouldConfig) {
        // 初始化
        this.mouldConfig = {
          modlue0: {
            modlueIndex: 0,
            isShow: true,
            isBig: true,
            imgSrcBig: '',
            imgSrcSmall: ''
          },
          modlue1: {
            modlueIndex: 1,
            isShow: true,
            abstractIsShow: true,
            isMerge: true,
            showSize: 3,
            imgSrc: '',
            type: 1,
            titleText: '编辑推荐'
          },
          modlue2: {
            isShow: true,
            showSize: 2,
            modlueIndex: 2,
            abstractIsShow: true,
            imgSrc: ''
          },
          modlue3: {
            isShow: true,
            modlueIndex: 3,
            abstractIsShow: false,
            coverIsShow: false,
            showNumber: -1,
            columnIsShow: true,
            imgSrc: '',
            titleZh: '当期目录',
            titleEn: 'Current Catalogue',
            titleTranslationIsShow: false,
            authorsTranslationIsShow: false,
            articleColumnTranslationIsShow: false,
            keywordsTranslationIsShow: false
          },
          modlue4: {
            isShow: false,
            abstractIsShow: false,
            modlueIndex: 4,
            showNumber: '',
            disabled: false,
            columnIsShow: true,
            imgSrc: ''
          },
          modlue5: {
            isShow: true,
            modlueIndex: 5,
            showNumber: 1,
            disabled: false,
            imgSrc: ''
          },
          modlue6: {
            isShow: true,
            showMagAbout: true,
            modlueIndex: 6,
            imgSrc: '',
            type: 1
          },
          modlue7: {
            modlueIndex: 7,
            isShow: true,
            imgSrc: '',
            type: 1
          },
          modlue8: {
            modlueIndex: 8,
            isShow: true,
            imgSrc: ''
          },
          modlue9: {
            modlueIndex: 9,
            isShow: false,
            imgSrc: ''
          },
          modlue10: {
            modlueIndex: 10,
            isShow: false,
            imgSrc: ''
          },
          modlue11: {
            modlueIndex: 11,
            isShow: false,
            imgSrc: ''
          }
        }
      }
      if (this.ruleForm.templateType === 1) {
        // 自科遥感版
        let showModuleList = ['modlue0', 'modlue1', 'modlue2', 'modlue3', 'modlue4', 'modlue5', 'modlue6', 'modlue7', 'modlue8', 'modlue9']
        this.handleMouldConfig('zk', showModuleList, [])
      } else if (this.ruleForm.templateType === 2) {
        // 社科版
        let showModuleList = ['modlue0', 'modlue1', 'modlue3', 'modlue6', 'modlue7', 'modlue8']
        let notShowModuleList = ['modlue2', 'modlue4', 'modlue5', 'modlue9']
        this.handleMouldConfig('sk', showModuleList, notShowModuleList)
      } else if (this.ruleForm.templateType === 3) {
        // 医学版
        this.mouldConfig.modlue4.showNumber = -1
        let showModuleList = ['modlue0', 'modlue4', 'modlue6', 'modlue7', 'modlue9']
        let notShowModuleList = ['modlue1', 'modlue2', 'modlue3', 'modlue5', 'modlue8']
        this.handleMouldConfig('yx', showModuleList, notShowModuleList)
      } else if (this.ruleForm.templateType === 4) {
        // 含能材料版
        this.mouldConfig.modlue3.showNumber = -1
        let showModuleList = ['modlue0', 'modlue3', 'modlue7', 'modlue9', 'modlue10', 'modlue11']
        let notShowModuleList = ['modlue1', 'modlue2', 'modlue4', 'modlue5', 'modlue6', 'modlue8']
        this.handleMouldConfig('hncl', showModuleList, notShowModuleList)
      }
    },
    handleMouldConfig(imgSrcTag, showModuleList, notShowModuleList) {
      for (const key in this.mouldConfig) {
        if (this.mouldConfig.hasOwnProperty(key)) {
          if (showModuleList.length > 0) {
            showModuleList.map(item => {
              if (this.$route.params.templateState == 'create') {
                this.mouldConfig[item].isShow = true
              }
              let handleItem = item.replace('modlue', 'module') // 兼容一下
              if (item === 'modlue0') {
                this.mouldConfig[item].imgSrcSmall = require(`@/assets/img/template_img/${imgSrcTag}/${handleItem}-small.png`)
                this.mouldConfig[item].imgSrcBig = require(`@/assets/img/template_img/${imgSrcTag}/${handleItem}.png`)
              } else if (imgSrcTag === 'sk' && (item == 'modlue6' || item == 'modlue7')) {
                let type = String(this.mouldConfig[item].type)
                this.mouldConfig[item].imgSrc = require(`@/assets/img/template_img/${imgSrcTag}/${handleItem}-type${type}.png`)
              } else {
                this.mouldConfig[item].imgSrc = require(`@/assets/img/template_img/${imgSrcTag}/${handleItem}.png`)
              }
            })
          }
          if (notShowModuleList.length > 0) {
            if (this.$route.params.templateState == 'create') {
              notShowModuleList.map(item => {
                this.mouldConfig[item].isShow = false
              })
            }
          }
        }
      }
    },
    templateTypeChange(value) {
      this.initMouldConfig()
    },
    // 初始化数据
    initMouldConfig() {
      this.templateName = ''
      this.isShow = true
      if (this.$route.params.templateState !== 'edit') {
        this.formatterData()
      }
    },
    // 提交保存模版配置
    submitTemplateInfo() {
      let params = {}
      let subDataConfig = this.mouldConfig

      for (const key in subDataConfig) {
        if (subDataConfig.hasOwnProperty(key)) {
          // 去除imgSrc/id/disabled 前端自定义的属性
          delete subDataConfig[key].imgSrc
          delete subDataConfig[key].id
          delete subDataConfig[key].disabled
          delete subDataConfig[key].imgSrcSmall
          delete subDataConfig[key].imgSrcBig
          // showSize showNumber可能为空
          if (subDataConfig[key].showSize == '') {
            subDataConfig[key].showSize = 0
          }
          if (subDataConfig[key].showNumber == '') {
            subDataConfig[key].showNumber = 0
          }
        }
      }

      // 社科模版modlue02
      if (this.currentTemplateType == 2) {
        subDataConfig['modlue2'].isShow = false
      }

      if (this.$route.params.templateState == 'create') {
        // templateType :  模板类型  0:主页模板  1:目录模板
        params = {
          type: this.ruleForm.templateType,
          name: this.ruleForm.templateName,
          mouldConfig: JSON.stringify(subDataConfig),
          templateType: 0
        }
      } else {
        params = {
          type: this.ruleForm.templateType,
          name: this.ruleForm.templateName,
          mouldConfig: JSON.stringify(subDataConfig),
          id: this.$route.params.templateId,
          templateType: 0
        }
      }

      if (!this.ruleForm.templateName) {
        this.$message({
          message: '请填写模版名称',
          type: 'error'
        })
      }
      this.$refs.handleTemplate.validate(valid => {
        if (valid) {
          submitWorktemplate(params).then(res => {
            if (res && res.data && res.data.status === 0) {
              this.$router.push({
                name: 'webJournalTemplate'
              })
            } else {
              this.$message({
                message: res.data.message,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取模版详情
    getTemplateDetail() {
      const params = {
        id: this.$route.params.templateId
      }
      getTemplate(params).then(res => {
        if (res && res.data && res.data.status === 0) {
          this.ruleForm.templateName = res.data.data.name || ''
          this.ruleForm.templateType = res.data.data.type || 1
          this.mouldConfig = JSON.parse(res.data.data.mouldConfig)
          this.formatterData(this.mouldConfig)
          this.isShow = true
        }
      })
    },
    goToWebTemplate() {
      this.$router.push({
        name: 'webJournalTemplate'
      })
    }
  }
}
</script>
<style lang="scss">
.handle-template {
  .templateType-list {
    .templateType-item-img {
      img {
        object-fit: contain;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.handle-template {
  text-align: left;
  // 模版类别
  .templateType-list {
    display: flex;
    width: 668px;
    padding: 10px;
    border: 1px solid #cccccc;
    .templateType-item {
      margin-right: 20px;
      display: inline-block;
      .templateType-item-img {
        width: 140px;
        height: 180px;
        display: block;
      }
    }
  }
}
.edits {
  background: #f5f7fd;
  padding: 40px 160px 40px;
  position: relative;
}
.crumbs {
  li {
    cursor: pointer;
  }
}
.mag-cover-box {
  position: relative;

  overflow: hidden;
  .mag-cover {
    width: 158px;
    height: 206px;
    border: 1px solid #ddd;
    background: #f4f4f4;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .upload-demo {
    width: 158px;
    height: 206px;
    position: absolute;
    top: 0;
  }
  .postion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .tip {
    color: #999;
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
    line-height: 24px;
  }
}
</style>
